<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          dark
          class="mb-2 mt-2"
          @click="magicFirestore()"
        >MAGIC</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {db} from '../firebase'
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc } from "firebase/firestore";
export default {
  data() {
    return {
      
    }
  },

  methods: {
    async magicFirestore(){
      //DELETE BULK
      //const q = query(collection(db, "interactions"), where("email", "==", "Enrique.soto@anukin.com"));
      
      //UPDATE FIELDS
      const q = query(collection(db, "tags/A100/list"))

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {

        console.log(doc.id)
        //this.myDelete(doc.id)
        //this.addField(doc.id)
        
      });
    },

    async myDelete(docToDelete){
      await deleteDoc(doc(db, "interactions", docToDelete));
    },

    async addField(docToUpdate){
      const docRef = doc(db, "tags/A100/list", docToUpdate);
      await updateDoc(docRef, {
        enabled: true
      })
    }
  }

}
</script>

<style>

</style>